import { graphql } from 'gatsby';
import { object } from 'prop-types';
import * as styles from './index.module.scss';
import { Wrap } from '../../components/sections-wrap';

function Privacy({ data }) {
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <main className={styles.main}>
          <article className={styles.entry}>
            <header>
              <h1 className={styles.title}>{data?.wpPage?.title}</h1>
            </header>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={
                  { __html: data?.wpPage?.content }
                }
            />
          </article>
        </main>
      </Wrap>
    </div>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/privacy/"}) {
      content
      title
      uri
      ...SeoPage
    }
  }
`;

Privacy.propTypes = {
  data: object,
};

Privacy.defaultProps = {
  data: {},
};

export default Privacy;
export { Head } from '../../components/seo/seo';
